.sidebar {
  width: 225px;
  border-left: #e0e0e0 1px solid;
  background-color: #fff;
  margin-right: 0px;
  transition: margin-right 0.3s ease-in-out;
}

.sidebar.hidden {
  margin-right: -225px;
}

.sidebar .toggle-button {
  position: fixed;
  top: 0px;
  right: 0px;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
}

.sidebar .toggle-button svg {
  fill: #ccc;
}

.sidebar .toggle-button:hover svg {
  fill: #fff;
}

.sidebar.hidden .toggle-button {
  transform: rotate(180deg);
}

.sidebar.hidden .toggle-button svg {
  fill: #000;
}

.sidebar .section-header {
  height: 40px;
  background: #000;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
}

.sidebar .section-body {
  padding: 20px;
}

.sidebar .custom-attribute {
  margin-bottom: 15px
}

.sidebar .content-header {
  display: block;
  font-weight: bold;
  margin: 5px 0;
}